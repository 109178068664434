/* Hands & Face ---------------------------------- */

.hand,
#face {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    width: 50vmin;
    height: 50vmin;
    box-shadow: 0 0 2vmin #333 inset, 0 0 4vmin #111;
}

.hand.smooth {
    transition: transform 1.05s linear;
}

#face {
    z-index: 2;
    width: 40vmin;
    height: 40vmin;
    border-radius: 40vmin;
    background-color: #bbd;
    box-shadow: 0 0 0 1px #111 inset, 0 0 2vmin #333 inset, 0 0 4vmin #111;
    box-shadow: 0 0 0 1px #111 inset, 0 0 17vmin rgba(0, 0, 0, 0.5) inset, 0 0 4vmin #111;
}

#hour,
#minute {
    background-color: #eb8;
    background-image: url('images/texture.jpg');
    background-size: 100%;
    background-blend-mode: color-burn;
    border-radius: 50% 0 50% 50%;
}

#hour {
    filter: saturate(0.5);
}

#minute {
    z-index: 1;
}

#second {
    z-index: 3;
    width: 3vmin;
    height: 37vmin;
    background-image: radial-gradient(#feb 0, #640 100%);
    border-radius: 5vmin / 50vmin;
    box-shadow: 0 0 0.5vmin #000;
}

.hand-point {
    float: right;
    width: 8vmin;
    height: 8vmin;
    border-bottom-left-radius: 75%;
    background: rgba(0, 0, 0, 0.5);
    background: -webkit-gradient(linear, left bottom, right top,
        color-stop(0, rgba(0, 0, 0, 1)), color-stop(10%, rgba(0, 0, 0, 1)),
        color-stop(90%, rgba(0, 0, 0, 0)), color-stop(100%, rgba(0, 0, 0, 0)));
}
