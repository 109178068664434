/* Options --------------------------------------- */

@import '_option-item';

.options {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    top: 0;
    z-index: 4;
    width: 45vw;
    height: 100%;
    color: #ccc;
    transition: transform 300ms;
    background: linear-gradient(rgba(0, 0, 0, 0.95) 0, rgba(51, 51, 51, 0.95) 20%,
        rgba(51, 51, 51, 0.95) 80%, rgba(0, 0, 0, 0.95) 100%);
    background: linear-gradient(rgba(0, 0, 0, 0.95) 0,
        rgba(51, 51, 51, 0.95) calc((100% - (25vmax + 25vmin)) / 2),
        rgba(51, 51, 51, 0.95) calc(((100% - (25vmax + 25vmin)) / 2) + (25vmax + 25vmin)),
        rgba(0, 0, 0, 0.95) 100%);

    &.show {
        transform: translateX(0) !important;
    }
}

#options-left {
    left: 0;
    padding-right: 5vw;
    text-align: right;
    transform: translateX(-50vw);
}

#options-right {
    left: 50vw;
    padding-left: 5vw;
    text-align: left;
    transform: translateX(50vw);
}

.option-label {
    @extend %option-item;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: #888;
}

#options-toggle {
    position: fixed;
    top: 2.5vmax;
    right: -2.5vmax;
    z-index: 5;
    background-color: #111;
    border-color: #555;
    transition: transform 300ms;

    @media (min-width: 900px) {
        right: -25px;
    }

    &::before {
        content: 'options';
        display: block;
        height: 100%;
        margin-left: calc(-9vmax - 3.75em);
        color: #888;
        line-height: 4.5vmax;
        letter-spacing: 0.01em;
        pointer-events: none;
        opacity: 0;
        transition: opacity 300ms;

        @media (min-width: 900px) {
            line-height: 100%;
        }
    }

    /* &:hover::before {
        opacity: 1;
    } */

    &.show {
        transform: translateX(-5vmax);

        &.show::before {
            opacity: 1;
        }
    }
}

%open-close {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    transition: opacity 300ms;

    &.bg {
        background-color: #292929;
        background-size: 66%;
    }
}

#options-open {
    @extend %open-close;
    opacity: 1;
    background-image: url('images/menu.svg');

    .show & {
        opacity: 0;
    }
}

#options-close {
    @extend %open-close;
    opacity: 0;
    background-image: url('images/close.svg');

    .show & {
        opacity: 1;
    }
}

#option-alarm::after,
#option-hue::after {
    content: 'off';
}

#option-alarm.on::after,
#option-hue.on::after {
    content: 'on';
}

#option-motion::after {
    content: 'tick';
}

#option-motion.smooth::after {
    content: 'smooth';
}
