/* Numbers --------------------------------------- */

.number {
    position: absolute;
    color: #292929;
    font-weight: bold;

    .inv-star &,
    .swirl &,
    .tent & {
        color: #bbd;
    }

    .lines &,
    .star & {
        display: none;
    }
}

.num-x {
    top: calc(50% - 3.25vmin);
}

.num-y {
    left: 0;
    right: 0;
    margin: auto;
}

#three {
    right: 5vmin;

    .spiral & {
        right: calc(50vw - 25.5vmax);
    }

    .swirl & {
        right: calc(50vw - 30.3vmax);
        font-size: 8vmin;
    }
}

#six {
    bottom: 5vmin;

    .spiral & {
        bottom: calc(50% - 33.5vmax);
    }

    .swirl & {
        bottom: calc(50% - 3vmin - 34.2vmax)
    }
}

#nine {
    left: 5vmin;

    .spiral & {
        left: calc(50vw - 37.5vmax);
    }

    .swirl & {
        left: calc(50vw - 40.3vmax);
    }
}

#twelve {
    top: 5vmin;
    margin-left: -0.05em;
    letter-spacing: -0.025em;

    .spiral & {
        top: calc(50% - 45.5vmax);
    }

    .swirl & {
        top: calc(50% - 1.5vmin - 44.25vmax);
        font-size: 3vmin;
    }
}
