/* Buttons --------------------------------------- */

@import '_option-item';

button {
    @extend %option-item;
    display: block;
    width: 5vmax;
    min-width: 30px;
    cursor: pointer;
    color: rgba(187, 187, 153, 0.75);
    border: 2px solid #292929;
    -webkit-tap-highlight-color: transparent;

    @media (min-width: 900px) {
        width: 50px;
    }

    &:focus {
        outline: 0;
    }

    &.bg {
        background-color: rgba(187, 187, 153, 0.75);
    }

    &::after {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0 0 0 8vmax;
        text-align: left;
        font: normal 5vmin sans-serif;

        @media (min-width: 900px) {
            font-size: 40px;
            padding-left: 75px;
            line-height: 100%;
        }
    }

    &.inv-star::after {
        content: 'inv-star';
    }

    &.lines::after {
        content: 'lines';
    }

    &.spiral::after {
        content: 'spiral';
    }

    &.star::after {
        content: 'star';
    }

    &.swirl::after {
        content: 'swirl';
    }

    &.tent::after {
        content: 'tent';
    }

    &.none::after {
        content: 'none';
    }
}
