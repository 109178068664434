/* Partial for Option Items ---------------------- */

%option-item {
    height: 5vmax;
    min-height: 30px;
    margin: 2.5vmin 0;
    padding: 0;
    white-space: nowrap;
    font: normal 5vmin sans-serif;
    letter-spacing: 0.01em;

    @media (min-width: 900px) {
        height: 50px;
        border-width: 3px;
        font-size: 40px;
        line-height: 45px;
    }
}
