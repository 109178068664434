/* Miscellaneous --------------------------------- */

html {
    height: 100%;
    overflow: hidden;
}

body {
    height: 100%;
    margin: 0;
    overflow: hidden;
    font: normal 6vmin sans-serif;
    text-align: center;
}
