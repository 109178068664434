/* Firefox Specific ------------------------------ */

@-moz-document url-prefix() {
    .bg {
        background-color: #2c3a7f;

        &.alarm {
            background-color: yellowgreen;
        }

        &.alarm-alt {
            background-color: yellow;
        }
    }

    #hour,
    #minute {
        background: #ccb56f !important;
    }
}
