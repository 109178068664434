/* Backgrounds ----------------------------------- */

.bg {
    background-color: #bb9;
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
}

.inv-star {
    background-image: url('images/inv-star.svg');
}

.lines {
    background-image: url('images/lines.svg');
}

.spiral {
    background-image: url('images/spiral.svg');
}

.star {
    background-image: url('images/star.svg');
}

.swirl {
    background-image: url('images/swirl.svg');
}

.tent {
    background-image: url('images/tent.svg');
}

.none {
    background-image: none;
}

.alarm {
    background-color: yellowgreen;
}

.alarm-alt {
    background-color: yellow;
}

.bell {
    background-image: url('images/bell.svg');
    background-size: 66%;
}

.color-grad {
    background-image: linear-gradient(rgba(127, 0, 0, 0.5) 0,
        rgba(0, 127, 0, 0.5) 50%, rgba(0, 0, 127, 0.5) 100%);
}
